import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SV } from 'UI/apps/SoftwareVersionApp/SoftwareVersion';
import { getSoftwareVersion } from '../core__software-version-store';

const SoftwareVersionApp_React = () => {
  const softwareVersion = useSelector(state => state.data.softwareVersion);
  const dispatch = useDispatch();

  useEffect(() => {
    if (softwareVersion === 'unknown') {
      dispatch(getSoftwareVersion({ isSSR: false }));
    }
  }, [softwareVersion]); // eslint-disable-line react-hooks/exhaustive-deps

  return <SV>{softwareVersion}</SV>;
};

export default SoftwareVersionApp_React;
