import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReduxProvider } from 'react-redux';
import createStore, {
  getSoftwareVersion,
} from './core__software-version-store';
import ScDecoder from 'Services/json/core__decoder';
import { TranslationsProvider } from 'Services/translations/core__translations';
import SoftwareVersionReact from './component/core__SoftwareVersionReact';
import { stringsToTranslate } from './core__software-version-translations';

export const Translations = new TranslationsProvider('SoftwareVersionApp');

class SoftwareVersionApp {
  constructor(properties) {
    Object.assign(this, properties);

    if (this.nodeSSR) {
      Translations.get = properties.Translations?.get.bind(
        properties.Translations
      );
      Translations.getAll = properties.Translations?.getAll?.bind(
        properties.Translations
      );
      return;
    }
    const reduxSelector = document.querySelector(`#redux${this.appSection.id}`);
    reduxSelector
      ? this.start(
          new ScDecoder(JSON.parse(reduxSelector.innerHTML)),
          reduxSelector
        )
      : this.start();
  }

  start(data, reduxSelector) {
    const ReactInstance = this.getReactApp();
    Translations.setAll(data);
    const jsx = (
      <ReduxProvider store={createStore(data || {})}>
        <ReactInstance />
      </ReduxProvider>
    );

    ReactDOM[data ? 'hydrate' : 'render'](jsx, this.appSection);
    if (reduxSelector) reduxSelector.remove();
  }

  getReactApp() {
    SoftwareVersionReact.serverFetch = {
      createStore: ssrData =>
        createStore({
          ...ssrData,
        }),
      // tell the server to fetch the software version before sending the page
      fetchData: (url, ssRequester, appConfig, isSSR) =>
        getSoftwareVersion({ ssRequester, isSSR }),
      strToTranslate: stringsToTranslate,
    };

    return SoftwareVersionReact;
  }
}

export default SoftwareVersionApp;
