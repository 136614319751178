import styled from 'styled-components';
import { FONT } from '../../../ui-library/globals/colours';

export const SV = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-right: 24px;
  padding-bottom: 20px;
  color: ${FONT.darkMute};
`;
