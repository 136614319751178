import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { getRequest } from 'Services/http/core__axios';

const initialState = {
  data: {
    softwareVersion: 'unknown',
  },
};

export const dataReducer = (state = {}, action) => {
  switch (action.type) {
    case 'SOFTWARE_VERSION':
      return {
        ...state,
        softwareVersion: action.softwareVersion,
      };
    default:
      return state;
  }
};

export const getSoftwareVersion = ({ ssRequester, isSSR }) => dispatch => {
  const requester = isSSR ? ssRequester : getRequest;
  const url = `/fsb-api-rest/version`;

  return requester(url).then(res => {
    //handle the success response here
    dispatch({
      type: 'SOFTWARE_VERSION',
      softwareVersion: res.data['platform.release.name'],
    });
  });
};

//Combine reducers into a single reducer
export const reducer = combineReducers({
  data: dataReducer,
});

//this is a thunk middleware function, for initial api call and dispatch to store
export default ({ data = {} } = {}) =>
  createStore(
    reducer,
    { data: { ...initialState.data, ...data } },
    applyMiddleware(thunkMiddleware)
  );
